import React from "react";
import { TextField, Grid, InputAdornment, IconButton } from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Input = ({
  name,
  handleChange,
  label,
  half,
  autoFocus,
  type,
  handleShowPassword,
  helperText,
  defaultValue,
}) => (
  <TextField
    name={name}
    autoComplete="off"
    onChange={handleChange}
    variant="filled"
    required
    fullWidth
    label={label}
    autoFocus={autoFocus}
    type={type}
    helperText={helperText}
    defaultValue={defaultValue}
    sx={{
      "& .MuiInputLabel-root": {
        color: "white", // Change the label color
        '&:before': {
          borderBottomColor: 'blue', // Default border color
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottomColor: 'green', // Border color on hover
        },
        '&.Mui-focused:before': {
          borderBottomColor: 'purple', // Border color when focused
        },
        '&.Mui-error:before': {
          borderBottomColor: 'red', // Border color when error
        },
      },
      "& .MuiFilledInput-root": {
        "&:before": {
          borderBottomColor: "white", // Default border color
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottomColor: "green", // Border color on hover
        },
        "&.Mui-focused:before": {
          borderBottomColor: "blue", // Border color when focused
        },
      },
      '& .MuiIconButton-root': {
        color: 'white', // Custom color for the visibility icon
      },
      '& .MuiInputBase-input': {
        color: 'white', // Change the input text color
      },
    }}
    InputProps={
      name === "password"
        ? {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleShowPassword}>
                {type === "password" ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }
        : null
    }
  />
);

export default Input;
