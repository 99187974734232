import * as React from 'react';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,

} from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
}));
let theme = createTheme();
theme = responsiveFontSizes(theme);

export default function ResponsiveFontSizes() {
  return (

    <ThemeProvider theme={theme}>
      <Paper sx={{ width: '100%', p: 2, borderRadius: 4, display: 'flex', alignItems: 'center', backgroundColor: 'rgb(33 35 34);'}} >
        <Box><Item>
          <Typography variant="body" gutterBottom align="center"> 1 UPPERCASE</Typography>
        </Item>
        <Item>
          <Typography variant="body" gutterBottom align="center"> 1 lowercase</Typography>
        </Item>
        <Item>
          <Typography variant="body" gutterBottom align="center"> 1 Number</Typography>
        </Item>
        <Item>
          <Typography variant="body" gutterBottom align="center"> Minimum 8 characters</Typography>
        </Item>
        </Box>
      </Paper>
    </ThemeProvider>

  );
}
