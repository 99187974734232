import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyOpt, resendOTP } from "../../../Redux/actions/auth";
import { Grid, TextField, Button, Typography } from "@mui/material";


const Step4CheckPhone = () => {
    const [phoneVerificationCode, setPhoneVerificationCode] = useState("");
    const [resendTimer, setResendTimer] = useState(40); // Initial timer value in seconds
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const dispatch = useDispatch();

    const verifyOptError = useSelector((state) => state.userReducer.verifyOptError);
    const verifyOptMessage = useSelector((state) => state.userReducer.verifyOptMessage);
    const resendOTPState = useSelector((state) => state.userReducer.resendOTPLoading);
    const resendOTPError = useSelector((state) => state.userReducer.resendOTPError);
    const verifyOtpStatus = useSelector((state) => state.userReducer.verifyOtpStatus);
    const phoneNumber = useSelector((state) => state.createAccountDataState.createAccountData.phoneNumber);

    useEffect(() => {
        let interval;
        if (isTimerRunning) {
            interval = setInterval(() => {
                setResendTimer(prevTimer => {
                    if (prevTimer === 1) {
                        clearInterval(interval);
                        setIsTimerRunning(false);
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isTimerRunning, verifyOtpStatus]);

    const handleChange = (value) => {
        setPhoneVerificationCode(value);
    };

    const handleVerifyOTP = () => {
        console.log('verifyOPTError', verifyOptError)
        console.log('verifyOPTMessage', verifyOptMessage)
        const formData = {
            phoneNumber: phoneNumber,
            verificationCode: phoneVerificationCode,
        };


        dispatch(verifyOpt(formData));
       
        setTimeout(() => {
            setPhoneVerificationCode("");
        }, 2000);
    };

    const handleResendOTP = () => {
        setIsTimerRunning(true);
        setResendTimer(40); // Reset timer
        const formData = {
            phoneNumber: phoneNumber, // Assuming you want to resend OTP to this phone number
        };
        dispatch(resendOTP(formData));
    };

    return (
        <Grid container sx={{ p: 1, }} spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
                <Typography variant="h3" sx={{
                    color: '#333', fontSize: '26px',
                    fontWeight: '100',
                    textAlign: 'center',
                    padding: '61px 33px',
                    paddingBottom: '10px',
                    textTransform: 'capitalize',
                    fontStyle: 'inherit',
                    letterSpacing: '1px'
                }}>
                    Please Enter One-time Otp to verify your account
                </Typography>


                <Typography variant="h3" sx={{
                    color: '#333', fontSize: '14px',
                    fontWeight: '100',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                    paddingBottom: '65px',
                    fontStyle: 'inherit',
                    letterSpacing: '1px'
                }}>
                    A One-Time Password has been sent to {phoneNumber}
                </Typography>

                <div style={{ textAlign: 'center', paddingBottom: '21px' }}>
                    <TextField
                                            sx={{width: '55%' ,
                                                "& .MuiInputLabel-root": {
                                                  color: "white", // Change the label color
                                                  '&:before': {
                                                    borderBottomColor: 'blue', // Default border color
                                                  },
                                                  '&:hover:not(.Mui-disabled):before': {
                                                    borderBottomColor: 'green', // Border color on hover
                                                  },
                                                  '&.Mui-focused:before': {
                                                    borderBottomColor: 'purple', // Border color when focused
                                                  },
                                                  '&.Mui-error:before': {
                                                    borderBottomColor: 'red', // Border color when error
                                                  },
                                                },
                                                "& .MuiFilledInput-root": {
                                                  "&:before": {
                                                    borderBottomColor: "white", // Default border color
                                                  },
                                                  "&:hover:not(.Mui-disabled):before": {
                                                    borderBottomColor: "green", // Border color on hover
                                                  },
                                                  "&.Mui-focused:before": {
                                                    borderBottomColor: "blue", // Border color when focused
                                                  },
                                                },
                                                '& .MuiIconButton-root': {
                                                  color: 'white', // Custom color for the visibility icon
                                                },
                                                '& .MuiInputBase-input': {
                                                  color: 'white', // Change the input text color
                                                },
                                              }}
                        label="Enter Verification Code"
                        variant="outlined"
                        value={phoneVerificationCode}
                        onChange={(e) => handleChange(e.target.value)}
    
                    />
                </div>


                <div style={{ textAlign: 'center', paddingBottom: '3px' }}>
                    <Button variant="contained" onClick={handleVerifyOTP} sx={{
                        backgroundColor: '#007bff',
                        color: '#fff',
                        padding: '11px 104px;',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                            backgroundColor: '#0056b3',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        },
                    }} disabled={verifyOtpStatus === 'success'}>Verify OTP</Button>
                </div>

                {verifyOptError && <Typography variant="body1" sx={{ color: 'red', marginTop: 2,paddingLeft: '319px'}}>{verifyOptError}</Typography>}
                {verifyOptError === null && verifyOptMessage && <Typography variant="body1" sx={{ color: 'green', marginTop: 2,paddingLeft: '319px' }}>{verifyOptMessage}</Typography>}
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
                <div style={{
                    paddingLeft: '258px'
                }}>
                    <Typography variant="body1" sx={{ marginRight: 2 }}>Resend One-Time Password</Typography>
                </div>
                <div style={{
                    paddingLeft: '319px',
                    paddingTop: '17px'
                }}>
                    <Button variant="outlined" onClick={handleResendOTP} disabled={isTimerRunning || resendOTPState}>
                        {resendOTPState ? 'Resending...' : (isTimerRunning ? `Resending... (${resendTimer})` : 'Resend OTP')}
                    </Button>
                    {resendOTPError && <Typography variant="body1" sx={{ color: 'red', marginLeft: 2,paddingLeft: '319px',
                    paddingTop: '17px' }}>{resendOTPError}</Typography>}
                </div>


            </Grid>
        </Grid>
    );
};

export default Step4CheckPhone;
