import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardMedia,
  Grid,
  Typography,
  FormControl,
} from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";

import { Stack } from "@mui/material";

import { signin, signup } from "../../Redux/actions/auth";
import { AUTH } from "../../Redux/constants/actionTypes";
import useStyles from "./styles";
import Input from "./Step1CreateAccount/Input";
import Logo from "../../Img/Logo.png";
import { useTheme } from "@mui/system";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  experience: " ",
  firstNameRecipient: "",
  lastNameRecipient: "",
  tel: "",
  emailRecepient: "",
  date: "",
};

const SignUp = () => {
  const theme = useTheme();

  const whiteColor = theme.palette.common.white;

  const [form, setForm] = useState(initialState);
  const [isSignup, setIsSignup] = useState(false);
  const dispatch = useDispatch();
  const history = useNavigate();
  const classes = useStyles;
  let navigate = useNavigate();
  const { search } = useLocation();
  const priceId = new URLSearchParams(search).get("priceId");
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const switchMode = () => {
    setForm(initialState);
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
    navigate("/create");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(signup(form, history));
    if (isSignup) {
      {
        dispatch(signup(form, history));
        //history("/");
      }
    } else {
      dispatch(signin(form, priceId, history));
      //history("/");
    }
  };
  function NavigateHandle(e) {
    navigate("/");
  }
  let googleSuccess = async (res) => {
    const result = res?.profileObj;
    const token = res?.tokenId;
    try {
      dispatch({ type: AUTH, data: { result, token } });
      NavigateHandle();
      history("/");
    } catch (error) {
      console.log(error);
    }
  };

  const googleError = () =>
    console.log("Google Sign In was unsuccessful. Try again later");
  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: 1,
        height: "100vh",
        backgroundColor: "rgb(28 30 33);",
        // Root class for the input field
        "& .MuiTextField-root": {
          color: "white",
          fontFamily: "Arial",
          fontWeight: "bold",
          // Class for the border around the input field
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#2e2e2e",
            borderWidth: "2px",
          },
        },
        "& .MuiInputBase-root": {
          color: "white",
        },
        "& .MuiInputBase-input ": {
          color: "white",
        },
      }}
    >
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Card
            sx={{
              display: "flex",
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
            }}
          >
            <CardMedia
              component="img"
              sx={{ display: "flex", width: 100, }}
              image={Logo}
              alt="Live from space album cover"
            />
          </Card>
        </Grid>
        <Grid item>
          <Typography variant="h5">
            {isSignup ? "Sign up" : "Sign in"}
          </Typography>
        </Grid>

        <FormControl>
          <Grid
            item>
            <Input
              name="email"
              label="Email Address"
              handleChange={handleChange}
              type="email"
              sx={{
                '& .MuiInputBase-input': {
                  color: 'red', // Change the text color
                },
                '& .MuiInputLabel-root': {
                  color: 'red', // Change the label color
                },
              }}
              fontColor="green"
            />
          </Grid>
          <Grid item>
            <Input
              name="password"
              label="Password"
              handleChange={handleChange}
              type={showPassword ? "text" : "password"}
              handleShowPassword={handleShowPassword}
            />

            {isSignup && (
              <Input
                name="confirmPassword"
                label="Confirm Password"
                handleChange={handleChange}
                type="password"
              />
            )}
          </Grid>
          <Grid item>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              {isSignup ? "Sign Up" : "Sign In"}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={switchMode}>
              {isSignup
                ? "Already have an account? Sign in"
                : "Don't have an account? Sign Up"}
            </Button>
          </Grid>
        </FormControl>
      </Grid>
    </Stack>
  );
};
export default SignUp;
