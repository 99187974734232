import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  greetingAudioOn,
  greetingAudioOff,
  menuLeftOpen,
  menuLeftClose,
  menuRightOpen,
  menuRightClose,
  buttonAugieVisible,
  buttonAugieHide,
  augieVisible,
  augieHide,
  menuUrlOpen,
  menuUrlClose,
} from "../../../Redux/actions";
import { CUSTOM_EDIT } from "../../../Redux/constants/actionTypes";

import { experienceget } from "../../../Redux/actions/experience";
import { textToSpeechAction } from "../../../Redux/actions/textToTpeechAction";
import { withTranslation } from "react-i18next";
import { Box, Button, TextField, Typography, Stack, InputLabel, MenuItem, FormControl, Select, Container } from "@mui/material";
import useStyles from "./styles";
import { voices } from "./voices";
import Player from "../Player";
import AudioPlay from "../AudioPlay";
import CircularAudio from "./CircularAudio";


const avatars = [{ name: "RPM" }];

function TextToSpeechComponent(props) {
  const experienceState = useSelector((state) => state.experienceState);
  const customEditData = useSelector((state) => state.customEditState.customEditData);
  const voiceUrl = useSelector((state) => state.voiceUrl);

  const dispatch = useDispatch();
  const classes = useStyles;

  const [defaultVoiceName, setDefaultVoiceName] =
    useState("Matthew");
  const [defaultEngine, setDefaultEngine] =
    useState("standard");

  const [defaultText, setDefaultText] =
    useState(`Hi everyone!`);
  const [circularAudio, setCircularAudio] = useState(false);
  const [countCharacter, setCountCharacter] = useState(
    customEditData.textVoice?.length
  );
  const [audioUrl, setAudioUrl] = useState("");

  useEffect(() => {
    const userDate = JSON.parse(localStorage.getItem("profile"))?.result;
    dispatch(experienceget(({ id: "" + userDate?.longRandomString })))
  }, []);

  useEffect(() => {
    if (props.voiceUrlProp.url) {
      setAudioUrl(props.voiceUrlProp.url)
      setCircularAudio(false)
      dispatch({
        type: 'CUSTOM_EDIT', data: {
          ...customEditData,
          idVoice: props.voiceUrlProp.url,
        }
      });
    }
  }, [props.voiceUrlProp.url]);

  useEffect(() => {
    if (experienceState.experienceData?.result.idVoice) {
      setAudioUrl(experienceState.experienceData?.result.idVoice);
    }
  }, [experienceState.experienceData?.result?.idVoice])

  let changeVoice = (event) => {
    console.log(event.target.value)
    setDefaultVoiceName(event.target.value);
    for (const property in voices) {
      if (event.target.value == voices[property].name) {
        if (voices[property].StandardVoice == 'Yes') setDefaultEngine('standard')
        else if (voices[property].NeuralVoice == 'Yes') setDefaultEngine('neural')
      }

    }
  };

  let changeDefaultText = (event) => {
    setDefaultText(event.target.value)
    setCountCharacter(event.target.value.length);
    dispatch({
      type: CUSTOM_EDIT, data: {
        ...customEditData,
        textVoice: event.target.value,
      }
    });

  };

  let requestToTextToSpeech = () => {
    setCircularAudio(true)
    dispatch({ type: 'VOICE_URL_REQUESTED', payload: '' });
    dispatch(
      textToSpeechAction({ Text: defaultText, TextType: 'text', VoiceId: defaultVoiceName, Engine: defaultEngine }))
  }

  return (
    <div key={Math.random}>
      {/*
      <Typography variant="h6" gutterBottom>
        Avatar (info)
      </Typography>
      <Box sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="arportal-avatar">Brand Buddy Avatar</InputLabel>
          <Select
            id="voicesSelect"
            value={customEditData.avatar}
            label="Avatar">
            {avatars.map((voice) => {
              return (<MenuItem key={voice.name + "Voice"} value={voice.name}>{voice.name} </MenuItem>)
            })}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <TextField
          required
          id="firstName"
          name="text-to-speech-field"
          label="Speech text"
          fullWidth
          variant="outlined"
          multiline
          rows={12}
          onChange={changeDefaultText}
          value={customEditData.textVoice}
          className={classes.root}
          inputProps={{ style: { fontSize: 12, lineHeight: 1.5 } }} // font size of input text
          InputLabelProps={{ style: { fontSize: 16 } }}
        />
        <Typography variant="subtitle2">Characters: {countCharacter}</Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="text-to-speech-voices">Voice</InputLabel>
          <Select
            id="voicesSelect"
            value={defaultVoiceName}
            label="Voice"
            onChange={changeVoice}>
            {voices.map((voice) => {
              return (<MenuItem key={voice.name + Math.random()} value={voice.name}>{voice.language} {voice.name} {voice.gender}</MenuItem>)
            })}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ m: 2 }}>

      </Box>
      <Stack spacing={2} direction="column" sx={{ mb: 1 }} >
        {!circularAudio ? <Button variant="contained" onClick={requestToTextToSpeech}>Say it!</Button> :
          <CircularAudio />}
      </Stack>

      <AudioPlay url={audioUrl} />
      */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    audioGreeting: state.greetingAudio,
    buttonAugieVisibility: state.buttonAugie,
    greetingAudioFinishedEventProp: state.greetingAudioFinishedEvent,
    voiceUrlProp: state.voiceUrl,
    greetingAudioFinishedEventProp: state.greetingAudioFinishedEvent,
  };
};

const mapDispatchToProps = (dispatch) => ({
  greetingAudioOn,
  greetingAudioOff,
  menuLeftOpen,
  menuLeftClose,
  menuRightOpen,
  menuRightClose,
  buttonAugieVisible,
  buttonAugieHide,
  augieVisible,
  augieHide,
  menuUrlOpen,
  menuUrlClose,
  dispatch,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TextToSpeechComponent)
);

